<div [ngClass]="{ 'viewIntermediariesHome' : fromPage == 'homePage'}">
	<!-- Search box  -->
	<div class="filter-search-container clearfix">
		<!-- This block will be available on intermediary align page -->
		<!-- <div mdbDropdown class="dropdown">
			<button class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" type="button"
				id="dropdownMenuButton" mdbDropdownToggle>
				Dropdown button
			</button>
			<ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<li><a class="dropdown-item" href="#">Action</a></li>
				<li><a class="dropdown-item" href="#">Another action</a></li>
				<li><a class="dropdown-item" href="#">Something else here</a></li>
			</ul>
		</div> -->
		<div class="intermediary-filter-container">
			<div class="button-group">
				<button type="button" class="filter-type-btn dropdown-toggle" data-toggle="dropdown">Filter<span
						class="caret filter-caret"></span></button>
				<ul class="dropdown-menu filter-dropdown-container" style="background-color: #2150a3">
					<li class="filter-dropdown-li li-drop-down-value" *ngFor="let type of searchModel.type">
						<input type="checkbox" [(ngModel)]="type.checked" (click)="typeChecked(type, $event)" /><span
							class="filter-type-text" (click)="typeChecked(type, $event)">{{type.shortValue}}</span>
					</li>
					<li>
						<div class="cmButton intermediary-tab-btn apply-btn filter-button"
							(click)="searchIntermediary(false)">
							APPLY </div>
					</li>
				</ul>
			</div>
		</div>

		<div class="discipline-search-container">
			<select class="intermediary-dropdown-subject ng-pristine ng-valid ng-touched" (change)="selectDiscipline()"
				[(ngModel)]="selectedList.subject" tabindex="1">
				<!-- <option *ngIf="isBlankObject(selectedList.subject)" value="" disabled selected class="option-visible">{{disciplineMsg}}</option> -->
				<option *ngFor="let subjectList of subjectBeanList" [ngValue]="subjectList">{{subjectList.subjectDesc}}
				</option>
			</select>
		</div>

		<div class="form-inline intermediary-search-container">
			<input id="searchButtonId" class="intermediary-search-input" [(ngModel)]="searchModel.text" type="text"
				(change)="handleEnter($event)">
			<img class="cursor-pointer" style="padding-left:5px"
				title="{{globalVar?.languageString?.resources?.intermediaryAlignTab?.statementSearchIconTooltip}}"
				src="assets/images/search_icon.png" (click)="searchIntermediary(false)">
		</div>
		<div class="intermediary-code-search-container" [hidden]="fromPage !== 'homePage'">
			<input type="checkbox" [(ngModel)]="intermediaryCodeSearchEnabled" />
			<span
				class="intermediary-code-search-text">{{globalVar?.languageString?.resources?.intermediaryAlignTab?.intermediaryCodeSearchLabel}}</span>
			<span class="cmButton intermediary-tab-btn clear-text-btn"
				*ngIf="textParamValue.length > 0 || codeParamValue.length > 0"
				(click)="clearSearchedTexts()">{{globalVar?.languageString?.resources?.intermediaryAlignTab?.statementTextClearBtnLabel
				| uppercase}}</span>
		</div>
		<div class="intermediary-code-search-container">
			<div *ngIf="fromPage == 'homePage'" (click)="exportIntermediary()" class="intermediary-export-link"
				[hidden]="!(!globalVar?.generatingXLS && !globalVar?.downloadUrl)">
				Export skill/ Intermediary spreadsheet
			</div>
			<div class="intermediary-export-link" [hidden]="!(globalVar?.generatingXLS && !globalVar?.downloadUrl)">
				{{globalVar?.languageString?.resources?.manageTab?.manageUploadGeneratingMessage}}
			</div>
			<div class="intermediary-export-link" [hidden]="!(!globalVar?.generatingXLS && globalVar?.downloadUrl)"><a
					(click)="downloadFile()"
					title="{{globalVar?.languageString?.resources?.manageTab?.manageUploadDownloadLinkLabel}}">{{globalVar?.languageString?.resources?.manageTab?.manageUploadDownloadLinkLabel}}</a>
			</div>
		</div>
	</div>

	<!--Intermediary Code Search Section -->
	<!-- This block will be available on intermediary align page -->
	<div class="intermediary-code-search-container" *ngIf="fromPage !== 'homePage'	">
		<input type="checkbox" [(ngModel)]="intermediaryCodeSearchEnabled" />
		<span class="intermediary-code-search-text" [textContent]="'Search Codes Only'"></span>
		<span class="cmButton intermediary-tab-btn clear-text-btn" *ngIf="false" [textContent]="'Clear' | uppercase"
			(click)="clearSearchedTexts()"></span>
	</div>

	<!-- Searched tags container -->
	<div class="searched-text-section" *ngIf="textParamValue.length > 0 || codeParamValue.length > 0">
		<div class="form-inline">
			<div class="search-text-container" *ngFor="let searchtext of textParamValue">
				<label class="search-text-style">{{searchtext}}</label>
				<span class="search-text-close" (click)="removeSelectedText(searchtext)">x</span>
			</div>
			<div class="search-text-container" *ngFor="let searchtext of codeParamValue">
				<label
					class="search-text-style">{{globalVar?.languageString?.resources?.intermediaryAlignTab?.intermediaryCodeLabel}}{{searchtext}}</label>
				<span class="search-text-close" (click)="removeSelectedText(searchtext)">x</span>
			</div>

		</div>
	</div>
	<!-- Intermediary list section -->
	<div class="statement-tab-conatiner">
		<ul class="tabs">
			<li id="cart-tab-head" class="current tab-link alignment-tabs-common statement-tab"
				data-tab="statement-data-tab">{{statementTabName}}</li>
		</ul>
		<div id="statement-data-tab" class="statement-tab-content statement-tab current">
			<div id="infiniteScrollParentId" class="statement-search-listing-conatiner">
				<div infinite-scroll (scrolled)="loadMore()"
					[infiniteScrollContainer]="'.statement-search-listing-conatiner'" [fromRoot]="true">
					<div *ngFor="let intermediary of searchListItem">
						<div class="intermediary-statement row-fluid pointer-events:none"
							[ngClass]="{ 'mapped-intermediary' : intermediary?.addedToCart}">
							<span class="col-sm-2 intermediary-type-container"
								[ngClass]="getTypeBgColor(intermediary.type)">{{
								getIntermediaryTypeName(intermediary.type) }}</span>
							<span class="col-sm-9 search-intermediary-text-container"><span
									*ngIf="showIntermediaryCode(intermediary?.intermediaryStatementCode)">{{
									intermediary?.intermediaryStatementCode?.en }} - </span>{{
								intermediary?.prefLabel?.en }}</span>
							<span class="col-sm-1 intermediary-add-icon" *ngIf="fromPage != 'homePage'">
								<img src="assets/images/arrow_right_blue_round.png" title="Align Statement"
									[hidden]="intermediary?.addedToCart" (click)="addStatementToCart(intermediary)" />
							</span>
						</div>
					</div>
				</div>
				<div class="text-center search-loading-container">
					<span [hidden]="!statementLoading">
						<img src="assets/images/loading_32x32.gif" />
					</span>
					<span
						[hidden]="!recordsUnavailable">{{globalVar?.languageString?.resources?.intermediaryAlignTab?.statementNoSearchRecordMessage}}</span>
				</div>
				<!-- <div ui-splitbar class="stretch ui-splitbar"></div> -->

			</div>
		</div>
	</div>
</div>