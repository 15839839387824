<!-- <app-curriculum-menu></app-curriculum-menu> -->
<div class="currPreviewHeader">
    <div class="currPreviewHeaderButtonPanel">
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewBackBtnLabel}}"
            class="cmButton-goBack marginTop5px floatRight" style="height: 33px;" (click)="goBack()" />
        <span class="input-group-addon"><i class="fa fa-chevron-left floatRight backBtnIcon"></i></span>
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewDeleteBtnLabel}}"
            [hidden]="!showDeleteButton()"
            class="cmButton cmButton-grey cmButton-standard marginTop5px marginLeft15px floatRight"
            (click)="deleteCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
        <span *ngIf="currentCurriculumId !== curriculum?.curriculumId">
            <input type="submit"
                value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewExportForEditBtnLabel}}"
                [hidden]="!showEditButton()"
                class="cmButton cmButton-violet cmButton-large marginTop5px marginLeft15px floatRight"
                (click)="exportForEdit(curriculum)" />
        </span>
        <span *ngIf="currentCurriculumId == curriculum?.curriculumId">
            <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumGeneratingMessage}}"
                class="cmButton cmButton-violet cmButton-large marginTop5px marginLeft15px floatRight"
                (click)="exportForEdit(curriculum)" [hidden]="!(generatingXLSForEdit && !downloadUrlForEdit)"
                (disabled)="generatingXLSForEdit && !downloadUrlForEdit" />
            <a type="submit" class="cmButton cmButton-violet marginTop5px marginLeft15px floatRight"
                title="{{globalVar?.languageString?.resources?.homeTab?.curriculumDownloadLabelToolTip}}"
                [hidden]="!(!generatingXLSForEdit && downloadUrlForEdit)"
                href="api/curriculum/export/{{ downloadUrlForEdit }}">Download</a>
        </span>
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewEditBtnLabel}}"
            [hidden]="!showEditButton()"
            class="cmButton cmButton-red cmButton-standard marginTop5px marginLeft15px floatRight"
            (click)="editCurricula(curriculum)" />
        <!-- <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewAutoTagBtnLabel}}" [hidden]="!showAutoTagButton()" class="cmButton cmButton-grey marginTop5px marginLeft15px floatRight" style="width: 106px; height: 33px;" (click)="autoTagCurricula(curriculum)"/> -->
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewRejectBtnLabel}}"
            [hidden]="!showRejectButton()"
            class="cmButton cmButtonFull-red cmButton-standard marginTop5px marginLeft15px floatRight"
            (click)="rejectCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewApproveBtnLabel}}"
            [hidden]="!showApproveButton()"
            class="cmButton cmButtonFull-green cmButton-standard marginTop5px marginLeft15px floatRight"
            (click)="approveCurricula(curriculum, -1, -1, statusChangePopupTemp)" style="padding-left: 12px;" />
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewDraftBtnLabel}}"
            [hidden]="!showDraftButton()"
            class="cmButton cmButtonFull-grey cmButton-standard marginTop5px marginLeft15px floatRight"
            (click)="draftCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
        <input type="submit"
            value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewUndeleteBtnLabel}}"
            [hidden]="!showUndeleteButton()"
            class="cmButton cmButtonFull-green cmButton-large marginTop5px marginLeft15px floatRight"
            (click)="undeleteCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
        <input type="submit"
            value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewUnrejectBtnLabel}}"
            [hidden]="!showUnrejectButton()"
            class="cmButton cmButtonFull-green cmButton-large marginTop5px marginLeft15px floatRight"
            (click)="unrejectCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
        <input type="submit" value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewApproveBtnLabel}}"
            [hidden]="!showSendForApprovalButton()"
            class="cmButton cmButtonFull-green cmButton-large marginTop5px marginLeft15px floatRight"
            (click)="approveCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
        <input type="submit" value="{{ getLockStatusLabel(curriculum) }}" [hidden]="!showUnapproveButton()"
            class="cmButton cmButtonFull-green cmButton-large marginTop5px marginLeft15px floatRight"
            (click)="lockUnlockCurricula(curriculum)" />
        <input type="submit"
            value="{{globalVar?.languageString?.resources?.homeTab?.curriculumPreviewUnapproveBtnLabel}}"
            (disabled)="curriculum?.lockedForAlignment" [hidden]="!showUnapproveButton()"
            class="cmButton cmButtonFull-green cmButton-large marginTop5px marginLeft15px floatRight"
            (click)="draftCurricula(curriculum, -1, -1, statusChangePopupTemp)" />
    </div>

    <div>
        <div class="currPreviewHeaderTitlePanel marginBottom10" [hidden]="!curriculum">
            <div class="uriParent">
                <span class="font_20_666666">Display Title:</span>
                <span *ngIf="!displayTitleIdle" class="font_20_666666" style="font-size:25px; padding-right:20px;">{{
                    curriculum?.displayTitle
                    }}</span>
                <span *ngIf="displayTitleIdle" class="font_20_666666"
                    style="font-size:25px; padding-right:10px;">&#60;null&#62;</span>
                <span class="copyUriText">Standard URI:</span>
                <span class="copyUriTag"
                    [ngStyle]="{'color': displayTitleIdle || disableStandardURL || disableAllURI ? '#ccc' : '','cursor': displayTitleIdle || disableStandardURL || disableAllURI ? 'not-allowed':''}">loc-structure/{{curriculum?.curriculumId}}</span>
                <button class="copyUributton"
                    title="{{displayTitleIdle ? 'URI not available when Display Title is blank. Please request a Display Title.' : ''}}"
                    [ngStyle]="{'background': displayTitleIdle || disableStandardURL || disableAllURI ? '#ccc' : '','cursor': displayTitleIdle || disableStandardURL || disableAllURI ? 'not-allowed':''}"
                    (click)="displayTitleIdle || copyUriFunc('loc-structure/'+curriculum.curriculumId, $event)"
                    [disabled]="disableStandardURL || disableAllURI">Copy</button>
                <button
                    style="margin-left:10px; border-block-color:blue; background:mediumpurple; display:inline-flex; height:23px"
                    (click)="sentToRealize(sentToRealizeTemp,'loc-structure',curriculum.curriculumId)"><img style="max-width:15%"
                        src="assets/images/deployed_to_realize_Icon.png">Send to
                    Realize</button>
            </div>
        </div>
        <div class="currPreviewHeaderTitlePanel marginBottom10" [hidden]="!curriculum">
            <div class="uriParent">
                <span class="font_20_666666" style="font-size:14px;">{{ curriculum?.curriculumTitle }}</span>
            </div>
        </div>
        <div class="currPreviewGradeListPanel marginBottom10" [hidden]="!curriculum">
            <div class="uriParent">
                <span class="currPreviewGradeListLabel"> {{
                    globalVar?.languageString?.resources?.homeTab?.curriculumCmtGradeLabel }}</span>
                <span>
                    <i class="fa fa-info-circle gradeInfoIcon" (click)="showCmtGradeList(cmtGradeListTemp)"
                        title="Show related age ranges"></i>
                </span>
                <span *ngFor="let standardGradeBean of curriculum?.standardGradeList; let ind = index"
                    class="currPreviewGrade"
                    [ngClass]="{ 'currPreviewGradeSelected' : standardGradeBean?.grade == selectedStandardGrade}"
                    (click)="loadStandardGradeBean(standardGradeBean.grade, standardGradeBean.gradeUriList);">
                    {{ standardGradeBean?.grade }}
                </span>
                <span class="copyUriText">Grade URI:</span>
                <span class="copyUriTag"
                    [ngStyle]="{'color': displayTitleIdle || disableGradeURI || disableAllURI? '#ccc' : '','cursor': displayTitleIdle || disableGradeURI ||disableAllURI ? 'not-allowed':''}">{{'Grade'+selectedStandardGradeMod+'#'}}{{curriculum?.curriculumId}}</span>
                <button class="copyUributton"
                    title="{{displayTitleIdle ? 'URI not available when Display Title is blank. Please request a Display Title.' : ''}}"
                    [ngStyle]="{'background': displayTitleIdle ||disableGradeURI || disableAllURI ? '#ccc' : '','cursor': displayTitleIdle || disableGradeURI || disableAllURI? 'not-allowed':''}"
                    (click)="displayTitleIdle || copyUriFunc('Grade'+selectedStandardGradeMod+'#'+curriculum?.curriculumId, $event)"
                    [disabled]="disableGradeURI ||disableAllURI">Copy</button>
                <span *ngIf="gradeLoadingVisible" class="currPreviewGradeLoadingPanel"></span>
                <button
                style="margin-left:10px; border-block-color:blue; background:mediumpurple; display:inline-flex; height:23px"
                (click)="sentToRealize(sentToRealizeTemp,'Grade',curriculum.curriculumId)"><img style="max-width:15%"
                    src="assets/images/deployed_to_realize_Icon.png">Send to
                Realize</button>
            </div>
        </div>
        <div class="currPreviewGradeListPanel marginBottom10"
            [hidden]="!(curriculum && loadedGradeBeanList && loadedGradeBeanList.length > 0)">
            <div class="uriParent">
                <span class="currPreviewGradeListLabel"> {{
                    globalVar?.languageString?.resources?.homeTab?.curriculumLocalGradeLabel }} </span>
                <span *ngFor="let gradePreviewBean of loadedGradeBeanList; let ind = index" class="currPreviewGrade"
                    [ngClass]="{ 'currPreviewGradeSelected' : isSelectedGradeName(ind, gradePreviewBean.gradeName)}"
                    (click)="scrollToGradeName(ind, gradePreviewBean.gradeName); getSelectedLocalUri(gradePreviewBean)">
                    {{ gradePreviewBean?.gradeName }}
                </span>
                <span class="copyUriText">Local Grade URI:</span>
                <span class="copyUriTag"
                    [ngStyle]="{'color': displayTitleIdle || disableLocalGradeUrI ||disableAllURI ? '#ccc' : '','cursor': displayTitleIdle || disableLocalGradeUrI || disableAllURI? 'not-allowed':''}">loc/{{
                    selectedLocalGradeUri }}</span>
                <button class="copyUributton"
                    title="{{displayTitleIdle ? 'URI not available when Display Title is blank. Please request a Display Title.' : ''}}"
                    [ngStyle]="{'background': displayTitleIdle || disableLocalGradeUrI || disableAllURI? '#ccc' : '','cursor': displayTitleIdle || disableLocalGradeUrI || disableAllURI? 'not-allowed':''}"
                    (click)="displayTitleIdle || copyUriFunc('loc/'+selectedLocalGradeUri, $event)"
                    [disabled]="disableLocalGradeUrI ||disableAllURI">Copy</button>
                    <button
                    style="margin-left:10px; border-block-color:blue; background:mediumpurple; display:inline-flex; height:23px"
                    (click)="sentToRealize(sentToRealizeTemp,'loc',curriculum.curriculumId)"><img style="max-width:15%"
                        src="assets/images/deployed_to_realize_Icon.png">Send to
                    Realize</button>
            </div>
        </div>
    </div>
</div>

<ng-template #statusChangePopupTemp>
    <app-warning-popup [DoFunctionOnSuccess]="doUpdateCurriculumStatus" [PopupMessage]="popupData.popupMessage"
        [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef">
    </app-warning-popup>
</ng-template>

<ng-template #sentToRealizeTemp>
    <app-warning-popup [DoFunctionOnSuccess]="setToRealize" [PopupMessage]="popupData.popupMessage"
        [ConfirmationMessage]="popupData.confirmationMsg" [ButtonLabel]="popupData.buttonLabel" [modalRef]="modalRef">
    </app-warning-popup>
</ng-template>

<ng-template #cmtGradeListTemp>
    <div class="cmtGradeListTemp">
        <button type="button" class="popupXClose close pull-right" (click)="doClosePopup()">×
        </button>
        <div class="font_20_666666 marginBottom10 fontBold textCenter">CMT Grade (age)</div>
        <div class="padding10 textCenter">
            <table class="cmtGradeListTable">
                <tbody>
                    <tr *ngFor="let cmtGrade of curriculum?.cmtGradeList"
                        class="font_12_666666 padding5px border666666 whiteSpaceNoWrap">
                        <td class="padding5px borderRight666666">{{cmtGrade?.grade}} ({{cmtGrade?.gradeAge}})</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<div class="currPreviewLayout" *ngIf="curriculum?.status!='deleted'">
    <div class="currPreviewMetadata" id="menu">
        <div class="currPreviewMetadataLeft">
            <div class="currPreviewMetadataItemStatus" [hidden]="!curriculum?.status" title="Status">
                {{(curriculum?.status=='new')?'Draft Until Approved': 'Approved'}}
            </div>
        </div>
        <div class="currPreviewMetadataRight">
            <div class="currPreviewMetadataItemSourceUrl" [hidden]="!curriculum?.authority" title="Authority URL">
                <a href="{{curriculum?.authority}}" target="_blank">Authority</a>
            </div>
        </div>
        <!-- <div class="currPreviewMetadataRight">
            <div class="currPreviewMetadataItemSourceUrl" [hidden]="!curriculum?.sourceUrl" title="Source URL">
                <a href="{{curriculum?.sourceUrl}}" target="_blank">Source Curriculum</a>
            </div>
        </div> -->
        <div class="currPreviewMetadataRight">
            <div class="currPreviewMetadataItemSourceUrl" [hidden]="!curriculum?.lastUpdatedUrl"
                title="Additional Curriculum Information">
                <a href="{{curriculum?.lastUpdatedUrl}}" target="_blank">Additional Curriculum information</a>
            </div>
        </div>
        <div class="currPreviewMetadataRight">
            <div class="currPreviewMetadataItemSourceUrlDownload" (click)="generateCurriculumWithTagXLS(curriculum)"
                title="Generate Excel">Generate and Send Excel</div>
        </div>

    </div>
    <div *ngFor="let gradePreviewBean of loadedGradeBeanList; let ind = index" id="{{ gradePreviewBean.grade }}">
        <div id="{{ ind }}-{{ gradePreviewBean.gradeName }}"></div>
        <div class="currPreviewGradePanel">
            <div class="currPreviewGradeLabel">Grade {{gradePreviewBean?.grade}}
            </div>
            <div class="currPreviewGradeNameLabel">{{gradePreviewBean?.gradeName}}</div>
            <!--<div>{{ gradePreviewBean.columnsSize }}:{{ gradePreviewBean.maxLevel }}</div>-->
        </div>
        <div class="currPreviewTable">
            <table class="currPreviewTableHeader" style="width: 100%;">
                <tr>
                    <td class="currPreviewTableHeaderCell" style="width:165px;"><strong>Level 1</strong></td>
                    <td *ngIf="gradePreviewBean?.maxLevel > 2" class="currPreviewTableHeaderCell" style="width:195px;">
                        <strong>Level 2</strong>
                    </td>
                    <td *ngFor="let headerLevel of [] | arrayRange:3:(gradePreviewBean.maxLevel-1)"
                        [hidden]="!(gradePreviewBean.maxLevel > 3 && gradePreviewBean.maxLevel > headerLevel && gradePreviewBean.columnsSize > 1)"
                        class="currPreviewTableHeaderCell"
                        [ngStyle]="getHeaderColumnWidth(gradePreviewBean, headerLevel)">
                        <strong>Level {{
                            headerLevel }}</strong>
                    </td>
                    <td *ngIf="gradePreviewBean.columnsSize == 1" class="currPreviewTableHeaderCellIconExpand"
                        style="width:25px;" (click)="changeLearningObjColumns(gradePreviewBean)"></td>
                    <td *ngIf="gradePreviewBean.columnsSize > 1 && gradePreviewBean.maxLevel > 3"
                        class="currPreviewTableHeaderCellIconCollapse" style="width:20px;"
                        (click)="changeLearningObjColumns(gradePreviewBean)"></td>
                    <td class="currPreviewTableHeaderCell" style="box-sizing:content-box">
                        <strong>Lowest Level Curriculum Standards</strong>
                    </td>
                    <td [ngClass]="showAlignment ? 'currPreviewTableHeaderCellIconExpand' : 'currPreviewTableHeaderCellIconCollapse'"
                        style="width:25px;" (click)="changeAlignmentVisibility()"></td>
                    <td *ngIf="showAlignment" class="currPreviewTableHeaderCell currPreviewTableTagColumn"
                        style="border-left:0px;"><strong>Alignments</strong>
                    </td>
                </tr>
            </table>
            <table class="divTable" style="border-bottom: 2px solid #CCCCCC; font-size: 11px; height: 100%;">
                <tr class="divRow borderBottom" *ngFor="let topic of gradePreviewBean.topicsList; let ind = index">
                    <td [attr.colspan]="gradePreviewBean.maxLevel" class="divCell divValue" *ngIf="topic.leafNode">
                        <div><span class="searchResultTextStandardCode" *ngIf="topic.standardCode"> 
                            {{topic.standardCode }}
                            <!-- [textContent]="topic.topicDesc" -->
                            </span><span  [innerHTML]="topic.topicDesc"></span></div>
                    </td>
                    <!-- <td *ngIf="topic.leafNode" class="divCell divValue borderRight"
                        [ngClass]="alignmentColumn ? ((gradePreviewBean.topicsList.indexOf(topic) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1 && showAlignment) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty') : currentClass"
                        style="width: 1px;" (click)="changeAlignmentVisibility()"></td> -->
                    <td class="divCell divValue currPreviewTableTagColumn font_11_666666" *ngIf="topic.leafNode">
                        {{ topic.alignedCodes }}
                    </td>
                    <td class="divCell divValue borderRight" *ngIf="!topic.leafNode" style="width:165px;">
                        <div style='width: inherit'><span class="searchResultTextStandardCode"
                                *ngIf="topic.standardCode" style='word-break: break-word;'> ({{ topic.standardCode
                                }})</span> <span [innerHTML]="topic.topicDesc"></span></div>
                    </td>
                    <td class="divCell" *ngIf="!topic.leafNode">
                        <table class="divTable">
                            <tr class="divRow borderBottom" *ngFor="let subTopic1 of topic?.topicsList">
                                <td class="divCell divValue" *ngIf="subTopic1.leafNode">
                                    <div><span class="searchResultTextStandardCode" *ngIf="subTopic1?.standardCode"> ({{
                                            subTopic1.standardCode }})</span> <span
                                            [innerHTML]="subTopic1.topicDesc"></span>
                                    </div>
                                </td>
                                <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                    *ngIf="subTopic1.leafNode && showAlignment">
                                    {{ subTopic1.alignedCodes }}
                                </td>
                                <td class="divCell divValue borderRight" *ngIf="!subTopic1.leafNode"
                                    style="width:195px;">
                                    <div style='width: inherit;'><span class="searchResultTextStandardCode"
                                            *ngIf="subTopic1.standardCode" style='word-break: break-word;'> ({{
                                            subTopic1.standardCode }})</span> <span
                                            [innerHTML]="subTopic1.topicDesc"></span></div>
                                </td>
                                <td class="divCell" *ngIf="!subTopic1.leafNode">
                                    <table class="divTable">
                                        <tr class="divRow borderBottom"
                                            *ngFor="let subTopic2 of subTopic1.topicsList; let ind = index">
                                            <td class="divCell divValue borderRight"
                                                *ngIf="(gradePreviewBean.maxLevel > 3 && gradePreviewBean.columnsSize > 1)"
                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 3, subTopic2.leafNode)">
                                                <div><span class="searchResultTextStandardCode"
                                                        *ngIf="subTopic2.standardCode"> ({{
                                                        subTopic2.standardCode
                                                        }})</span> <span [innerHTML]="subTopic2.topicDesc"></span>
                                                </div>
                                            </td>
                                            <td class="divCell divValue borderRight" style="word-break: break-word;"
                                                *ngIf="subTopic2.leafNode"
                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 3, subTopic2.leafNode)">
                                                <div style='width: inherit'><span class="searchResultTextStandardCode"
                                                        *ngIf="subTopic2.standardCode" style='word-break: break-word;'>
                                                        ({{ subTopic2.standardCode }})</span> <span
                                                        [innerHTML]="subTopic2.topicDesc"></span></div>
                                            </td>
                                            <td class="divCell divValue borderRight"
                                                *ngIf="(subTopic2.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                style="width: 1px;" (click)="changeAlignmentVisibility()">
                                            </td>
                                            <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                *ngIf="subTopic2.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                style="width: 1px;">
                                            </td>
                                            <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                *ngIf="subTopic2.leafNode && showAlignment">
                                                {{ subTopic2.alignedCodes }}
                                            </td>
                                            <!-- <td class="divCell divValue borderRight" style="word-break: break-word;"
                                                *ngIf="subTopic2.leafNode"
                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 3, subTopic2.leafNode)">
                                                <div style='width: inherit'><span class="searchResultTextStandardCode"
                                                        *ngIf="subTopic2.standardCode" style='word-break: break-word;'>
                                                        ({{ subTopic2.standardCode }})</span> <span
                                                        [innerHTML]="subTopic2.topicDesc"></span></div>
                                            </td> -->
                                            <td class="divCell" *ngIf="!subTopic2.leafNode">
                                                <table class="divTable">
                                                    <tr class="divRow borderBottom"
                                                        *ngFor="let subTopic3 of subTopic2.topicsList; let ind = index">
                                                        <td class="divCell divValue borderRight"
                                                            *ngIf="gradePreviewBean.maxLevel == 4"
                                                            [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                            style="width: 1px;"
                                                            (click)="changeLearningObjColumns(gradePreviewBean)">
                                                            <!-- expand/collapse icon cell-->
                                                        </td>
                                                        <td class="divCell divValue borderRight"
                                                            *ngIf="subTopic3.leafNode"
                                                            [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 4, subTopic3.leafNode)">
                                                            <div><span class="searchResultTextStandardCode"
                                                                    *ngIf="subTopic3.standardCode"> ({{
                                                                    subTopic3.standardCode }})</span> <span
                                                                    [innerHTML]="subTopic3.topicDesc"></span></div>
                                                        </td>
                                                        <td class="divCell divValue borderRight"
                                                            *ngIf="(subTopic3.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                            [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                            style="width: 1px;" (click)="changeAlignmentVisibility()">
                                                        </td>
                                                        <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                            *ngIf="subTopic3.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                            style="width: 1px;">
                                                        </td>
                                                        <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                            *ngIf="subTopic3.leafNode && showAlignment">
                                                            {{ subTopic3.alignedCodes }}
                                                        </td>
                                                        <td class="divCell divValue borderRight"
                                                            *ngIf="!subTopic3.leafNode"
                                                            [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 4, subTopic3.leafNode)">
                                                            <div style='width: inherit'><span
                                                                    class="searchResultTextStandardCode"
                                                                    *ngIf="subTopic3.standardCode"
                                                                    style='word-break: break-word;'> ({{
                                                                    subTopic3.standardCode }})</span> <span
                                                                    [innerHTML]="subTopic3.topicDesc"></span></div>
                                                        </td>
                                                        <td class="divCell" *ngIf="!subTopic3.leafNode">
                                                            <table class="divTable">
                                                                <tr class="divRow borderBottom"
                                                                    *ngFor="let subTopic4 of subTopic3.topicsList; let ind = index">
                                                                    <td class="divCell divValue borderRight"
                                                                        *ngIf="gradePreviewBean.maxLevel == 5"
                                                                        [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                        style="width: 1px; "
                                                                        (click)="changeLearningObjColumns(gradePreviewBean)">
                                                                        <!-- expand/collapse icon cell-->
                                                                    </td>
                                                                    <td class="divCell divValue"
                                                                        *ngIf="subTopic4.leafNode"
                                                                        [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 5, subTopic4.leafNode)">
                                                                        <div><span class="searchResultTextStandardCode"
                                                                                *ngIf="subTopic4.standardCode"> ({{
                                                                                subTopic4.standardCode }})</span> <span
                                                                                [innerHTML]="subTopic4.topicDesc"></span>
                                                                        </div>
                                                                    </td>
                                                                    <td class="divCell divValue borderRight"
                                                                        *ngIf="(subTopic4.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                        [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                        style="width: 1px;"
                                                                        (click)="changeAlignmentVisibility()">
                                                                    </td>
                                                                    <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                        *ngIf="subTopic4.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                        style="width: 1px;">
                                                                    </td>
                                                                    <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                        *ngIf="subTopic4.leafNode && showAlignment">
                                                                        {{ subTopic4.alignedCodes }}
                                                                    </td>
                                                                    <td class="divCell divValue borderRight"
                                                                        *ngIf="!subTopic4.leafNode"
                                                                        [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 5, subTopic4.leafNode)">
                                                                        <div style='width: inherit'><span
                                                                                class="searchResultTextStandardCode"
                                                                                *ngIf="subTopic4.standardCode"
                                                                                style='word-break: break-word;'> ({{
                                                                                subTopic4.standardCode }})</span> <span
                                                                                [innerHTML]="subTopic4.topicDesc"></span>
                                                                        </div>
                                                                    </td>
                                                                    <td class="divCell" *ngIf="!subTopic4.leafNode">
                                                                        <table class="divTable">
                                                                            <tr class="divRow borderBottom"
                                                                                *ngFor="let subTopic5 of subTopic4.topicsList; let ind = index">
                                                                                <td class="divCell divValue borderRight"
                                                                                    *ngIf="gradePreviewBean.maxLevel == 6"
                                                                                    [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                                    style="width: 1px; "
                                                                                    (click)="changeLearningObjColumns(gradePreviewBean)">
                                                                                    <!-- expand/collapse icon cell-->
                                                                                </td>
                                                                                <td class="divCell divValue"
                                                                                    *ngIf="subTopic5.leafNode"
                                                                                    [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 6, subTopic5.leafNode)">
                                                                                    <div><span
                                                                                            class="searchResultTextStandardCode"
                                                                                            *ngIf="subTopic5.standardCode">
                                                                                            ({{ subTopic5.standardCode
                                                                                            }})</span> <span
                                                                                            [innerHTML]="subTopic5.topicDesc"></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="divCell divValue borderRight"
                                                                                    *ngIf="(subTopic5.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                                    [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                                    style="width: 1px;"
                                                                                    (click)="changeAlignmentVisibility()">
                                                                                </td>
                                                                                <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                                    *ngIf="subTopic5.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                                    style="width: 1px;" )>
                                                                                </td>
                                                                                <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                                    *ngIf="subTopic5.leafNode && showAlignment">
                                                                                    {{ subTopic5.alignedCodes }}
                                                                                </td>
                                                                                <td class="divCell divValue borderRight"
                                                                                    *ngIf="!subTopic5.leafNode"
                                                                                    [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 6, subTopic5.leafNode)">
                                                                                    <div style='width: inherit'><span
                                                                                            class="searchResultTextStandardCode"
                                                                                            *ngIf="subTopic5.standardCode"
                                                                                            style='word-break: break-word;'>
                                                                                            ({{ subTopic5.standardCode
                                                                                            }})</span> <span
                                                                                            [innerHTML]="subTopic5.topicDesc"></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="divCell"
                                                                                    *ngIf="!subTopic5.leafNode">
                                                                                    <table class="divTable">
                                                                                        <tr class="divRow borderBottom"
                                                                                            *ngFor="let subTopic6 of subTopic5.topicsList; let ind = index">
                                                                                            <td class="divCell divValue borderRight"
                                                                                                *ngIf="gradePreviewBean.maxLevel == 7"
                                                                                                [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                                                style="width: 1px; "
                                                                                                (click)="changeLearningObjColumns(gradePreviewBean)">
                                                                                                <!-- expand/collapse icon cell-->
                                                                                            </td>
                                                                                            <td class="divCell divValue"
                                                                                                *ngIf="subTopic6.leafNode"
                                                                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 7, subTopic6.leafNode)">
                                                                                                <div><span
                                                                                                        class="searchResultTextStandardCode"
                                                                                                        *ngIf="subTopic6.standardCode">
                                                                                                        ({{
                                                                                                        subTopic6.standardCode
                                                                                                        }})</span> <span
                                                                                                        [innerHTML]="subTopic6.topicDesc"></span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td class="divCell divValue borderRight"
                                                                                                *ngIf="(subTopic6.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                                                [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                                                style="width: 1px;"
                                                                                                (click)="changeAlignmentVisibility()">
                                                                                            </td>
                                                                                            <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                                                *ngIf="subTopic6.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                                                style="width: 1px;">
                                                                                            </td>
                                                                                            <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                                                *ngIf="subTopic6.leafNode && showAlignment">
                                                                                                {{
                                                                                                subTopic6.alignedCodes
                                                                                                }}
                                                                                            </td>
                                                                                            <td class="divCell divValue borderRight"
                                                                                                *ngIf="!subTopic6.leafNode"
                                                                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 7, subTopic6.leafNode)">
                                                                                                <div
                                                                                                    style='width: inherit'>
                                                                                                    <span
                                                                                                        class="searchResultTextStandardCode"
                                                                                                        *ngIf="subTopic6.standardCode"
                                                                                                        style='word-break: break-word;'>
                                                                                                        ({{
                                                                                                        subTopic6.standardCode
                                                                                                        }})</span> <span
                                                                                                        [innerHTML]="subTopic6.topicDesc"></span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td class="divCell"
                                                                                                *ngIf="!subTopic6.leafNode">
                                                                                                <table class="divTable">
                                                                                                    <tr class="divRow borderBottom"
                                                                                                        *ngFor="let subTopic7 of subTopic6.topicsList; let ind = index">
                                                                                                        <td class="divCell divValue borderRight"
                                                                                                            *ngIf="gradePreviewBean.maxLevel == 8"
                                                                                                            [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                                                            style="width: 1px; "
                                                                                                            (click)="changeLearningObjColumns(gradePreviewBean)">
                                                                                                            <!-- expand/collapse icon cell-->
                                                                                                        </td>
                                                                                                        <td class="divCell divValue"
                                                                                                            *ngIf="subTopic7.leafNode"
                                                                                                            [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 8, subTopic7.leafNode)">
                                                                                                            <div><span
                                                                                                                    class="searchResultTextStandardCode"
                                                                                                                    *ngIf="subTopic7.standardCode">
                                                                                                                    ({{
                                                                                                                    subTopic7.standardCode
                                                                                                                    }})</span>
                                                                                                                <span
                                                                                                                    [innerHTML]="subTopic7.topicDesc"></span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td class="divCell divValue borderRight"
                                                                                                            *ngIf="(subTopic7.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                                                            [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                                                            style="width: 1px;"
                                                                                                            (click)="changeAlignmentVisibility()">
                                                                                                        </td>
                                                                                                        <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                                                            *ngIf="subTopic7.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                                                            style="width: 1px;">
                                                                                                        </td>
                                                                                                        <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                                                            *ngIf="subTopic7.leafNode && showAlignment">
                                                                                                            {{
                                                                                                            subTopic7.alignedCodes
                                                                                                            }}
                                                                                                        </td>
                                                                                                        <td class="divCell divValue borderRight"
                                                                                                            *ngIf="!subTopic7.leafNode"
                                                                                                            [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 8, subTopic7.leafNode)">
                                                                                                            <div
                                                                                                                style='width: inherit'>
                                                                                                                <span
                                                                                                                    class="searchResultTextStandardCode"
                                                                                                                    *ngIf="subTopic7.standardCode"
                                                                                                                    style='word-break: break-word;'>
                                                                                                                    ({{
                                                                                                                    subTopic7.standardCode
                                                                                                                    }})</span>
                                                                                                                <span
                                                                                                                    [innerHTML]="subTopic7.topicDesc"></span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td class="divCell"
                                                                                                            *ngIf="!subTopic7.leafNode">
                                                                                                            <table
                                                                                                                class="divTable">
                                                                                                                <tr class="divRow borderBottom"
                                                                                                                    *ngFor="let subTopic8 of subTopic7.topicsList; let ind = index">
                                                                                                                    <td class="divCell divValue borderRight"
                                                                                                                        *ngIf="gradePreviewBean.maxLevel == 9"
                                                                                                                        [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                                                                        style="width: 1px; "
                                                                                                                        (click)="changeLearningObjColumns(gradePreviewBean)">
                                                                                                                    </td>
                                                                                                                    <td class="divCell divValue"
                                                                                                                        *ngIf="subTopic8.leafNode"
                                                                                                                        [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 9, subTopic8.leafNode)">
                                                                                                                        <div>
                                                                                                                            <span
                                                                                                                                class="searchResultTextStandardCode"
                                                                                                                                *ngIf="subTopic8.standardCode">
                                                                                                                                ({{
                                                                                                                                subTopic8.standardCode
                                                                                                                                }})</span>
                                                                                                                            <span
                                                                                                                                [innerHTML]="subTopic8.topicDesc"></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td class="divCell divValue borderRight"
                                                                                                                        *ngIf="(subTopic8.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                                                                        [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                                                                        style="width: 1px;"
                                                                                                                        (click)="changeAlignmentVisibility()">
                                                                                                                    </td>
                                                                                                                    <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                                                                        *ngIf="subTopic8.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                                                                        style="width: 1px;">
                                                                                                                    </td>
                                                                                                                    <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                                                                        *ngIf="subTopic8.leafNode && showAlignment">
                                                                                                                        {{
                                                                                                                        subTopic8.alignedCodes
                                                                                                                        }}
                                                                                                                    </td>
                                                                                                                    <td class="divCell divValue borderRight"
                                                                                                                        *ngIf="!subTopic8.leafNode"
                                                                                                                        [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 9, subTopic8.leafNode)">
                                                                                                                        <div
                                                                                                                            style='width: inherit'>
                                                                                                                            <span
                                                                                                                                class="searchResultTextStandardCode"
                                                                                                                                *ngIf="subTopic8.standardCode"
                                                                                                                                style='word-break: break-word;'>
                                                                                                                                ({{
                                                                                                                                subTopic8.standardCode
                                                                                                                                }})</span>
                                                                                                                            <span
                                                                                                                                [innerHTML]="subTopic8.topicDesc"></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td class="divCell"
                                                                                                                        *ngIf="!subTopic8.leafNode">
                                                                                                                    </td>
                                                                                                                    <table
                                                                                                                        class="divTable">
                                                                                                                        <tr class="divRow borderBottom"
                                                                                                                            *ngFor="let subTopic9 of subTopic8.topicsList;let ind = index">
                                                                                                                            <td class="divCell divValue borderRight"
                                                                                                                                *ngIf="gradePreviewBean.maxLevel == 10"
                                                                                                                                [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                                                                                style="width: 1px; "
                                                                                                                                (click)="changeLearningObjColumns(gradePreviewBean)">
                                                                                                                            </td>
                                                                                                                            <td class="divCell divValue"
                                                                                                                                *ngIf="subTopic9.leafNode"
                                                                                                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 10, subTopic9.leafNode)">
                                                                                                                                <div>
                                                                                                                                    <span
                                                                                                                                        class="searchResultTextStandardCode"
                                                                                                                                        *ngIf="subTopic9.standardCode">
                                                                                                                                        ({{
                                                                                                                                        subTopic9.standardCode
                                                                                                                                        }})</span>
                                                                                                                                    <span
                                                                                                                                        [innerHTML]="subTopic9.topicDesc"></span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td class="divCell divValue borderRight"
                                                                                                                                *ngIf="(subTopic9.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                                                                                [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                                                                                style="width: 1px;"
                                                                                                                                (click)="changeAlignmentVisibility()">
                                                                                                                            </td>
                                                                                                                            <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                                                                                *ngIf="subTopic9.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                                                                                style="width: 1px;">
                                                                                                                            </td>
                                                                                                                            <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                                                                                *ngIf="subTopic9.leafNode && showAlignment">
                                                                                                                                {{
                                                                                                                                subTopic9.alignedCodes
                                                                                                                                }}
                                                                                                                            </td>
                                                                                                                            <td class="divCell divValue borderRight"
                                                                                                                                *ngIf="!subTopic9.leafNode"
                                                                                                                                [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 10, subTopic9.leafNode)">
                                                                                                                                <div
                                                                                                                                    style='width: inherit'>
                                                                                                                                    <span
                                                                                                                                        class="searchResultTextStandardCode"
                                                                                                                                        *ngIf="subTopic9.standardCode"
                                                                                                                                        style='word-break: break-word;'>
                                                                                                                                        ({{
                                                                                                                                        subTopic9.standardCode
                                                                                                                                        }})</span>
                                                                                                                                    <span
                                                                                                                                        [innerHTML]="subTopic9.topicDesc"></span>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td class="divCell"
                                                                                                                                *ngIf="!subTopic9.leafNode">
                                                                                                                                <table
                                                                                                                                    class="divTable">
                                                                                                                                    <tr class="divRow borderBottom"
                                                                                                                                        *ngFor="let subTopic10 of subTopic9.topicsList; let ind = index">
                                                                                                                                        <td class="divCell divValue borderRight"
                                                                                                                                            *ngIf="gradePreviewBean.maxLevel == 11"
                                                                                                                                            [ngClass]="(subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0) ? ((gradePreviewBean.columnsSize == 1) ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse') : 'currPreviewTableCellIconEmpty'"
                                                                                                                                            style="width: 1px;"
                                                                                                                                            (click)="changeLearningObjColumns(gradePreviewBean)">

                                                                                                                                        </td>
                                                                                                                                        <td class="divCell divValue"
                                                                                                                                            *ngIf="subTopic10.leafNode"
                                                                                                                                            [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 11, subTopic10.leafNode)">
                                                                                                                                            <div>
                                                                                                                                                <span
                                                                                                                                                    class="searchResultTextStandardCode"
                                                                                                                                                    *ngIf="subTopic10.standardCode">
                                                                                                                                                    ({{
                                                                                                                                                    subTopic10.standardCode
                                                                                                                                                    }})</span>
                                                                                                                                                <span
                                                                                                                                                    [innerHTML]="subTopic10.topicDesc"></span>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td class="divCell divValue borderRight"
                                                                                                                                            *ngIf="(subTopic10.leafNode && subTopic1.topicsList.indexOf(subTopic2) == 0 && ind == 0)"
                                                                                                                                            [ngClass]="showAlignment ? 'currPreviewTableCellIconExpand': 'currPreviewTableCellIconCollapse'"
                                                                                                                                            style="width: 1px;"
                                                                                                                                            (click)="changeAlignmentVisibility()">
                                                                                                                                        </td>
                                                                                                                                        <td class="divCell divValue borderRight currPreviewTableCellIconEmpty"
                                                                                                                                            *ngIf="subTopic10.leafNode && (subTopic1.topicsList.indexOf(subTopic2) != 0 || ind > 0)"
                                                                                                                                            style="width: 1px;">
                                                                                                                                        </td>
                                                                                                                                        <td class="divCell divValue currPreviewTableTagColumn font_11_666666"
                                                                                                                                            *ngIf="subTopic10.leafNode && showAlignment">
                                                                                                                                            {{
                                                                                                                                            subTopic10.alignedCodes
                                                                                                                                            }}
                                                                                                                                        </td>
                                                                                                                                        <td class="divCell divValue borderRight"
                                                                                                                                            *ngIf="!subTopic10.leafNode"
                                                                                                                                            [ngStyle]="getSubTopicColumnWidth(gradePreviewBean, 11, subTopic10.leafNode)">
                                                                                                                                            <div
                                                                                                                                                style='width: inherit'>
                                                                                                                                                <span
                                                                                                                                                    class="searchResultTextStandardCode"
                                                                                                                                                    *ngIf="subTopic10.standardCode"
                                                                                                                                                    style='word-break: break-word;'>
                                                                                                                                                    ({{
                                                                                                                                                    subTopic10.standardCode
                                                                                                                                                    }})</span>
                                                                                                                                                <span
                                                                                                                                                    [innerHTML]="subTopic10.topicDesc"></span>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td class="divCell"
                                                                                                                                            *ngIf="!subTopic10.leafNode">
                                                                                                                                            <!-- copy here -->
                                                                                                                                        </td>

                                                                                                                                    </tr>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </table>
                                                                                                                </tr>
                                                                                                            </table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>

                </tr>
            </table>

        </div>
    </div>
</div>